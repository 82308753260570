import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import HeaderMain from '../components/HeaderMain';
import HeroSingle from '../components/pages/HeroSingle';
import '../styles/app.scss';

const ClassesPage = ({ data }) => {
    const { wordpressPage: post } = data;

    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <section className="page-section">
            <div className="container">
				<iframe
                            src="https://werockthespectrumtucson.wrtsfranchise.com/classes-healcode.php"
                            title="register"
                            className="healcode_widgets"
                        />

<div className="images-boxes">
<div className="images-box-four"><img src="https://werockthespectrumtucson.wrtsfranchise.com/wp-content/uploads/2022/01/Social-Skills-Classes-website.png" alt="classes-flyer" /></div>
<div className="images-box-four"><img src="https://werockthespectrumtucson.wrtsfranchise.com/wp-content/uploads/2022/01/Sip-and-Shop-Website.png" alt="classes-flyer" /></div>
<div className="images-box-four"><img src="https://werockthespectrumtucson.wrtsfranchise.com/wp-content/uploads/2022/01/Schools-Out-Website.png" alt="classes-flyer" /></div>
<div className="images-box-four"><img src="https://werockthespectrumtucson.wrtsfranchise.com/wp-content/uploads/2022/01/Music-with-Scott-Website-.png" alt="classes-flyer" /></div>
<div className="images-box-four"><img src="https://werockthespectrumtucson.wrtsfranchise.com/wp-content/uploads/2022/01/Monday-Meet-Up-Website.png" alt="classes-flyer" /></div>
<div className="images-box-four"><img src="https://werockthespectrumtucson.wrtsfranchise.com/wp-content/uploads/2022/01/Kids-Night-Out-Website-.png" alt="classes-flyer" /></div>
</div>
						</div>
                      

            </section>
	   

            <Footer />
        </>
    );
};

export default ClassesPage;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            content
            slug
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
